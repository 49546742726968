<template>
  <loading v-if="pageLoading" />

  <div v-else>
    <div
      v-if="userIsAgent && site.isSubscribed"
      class="message is-dark has-margin-bottom-100"
    >
      <div class="message-body has-padding-75 has-text-dark">
        <apart>
          <p>Re-generate last month's report.</p>
          <button
            class="button is-dark is-rounded is-outlined"
            :class="{ 'is-loading': processing }"
            @click="generateMonthlyReport"
          >
            Generate report
          </button>
        </apart>
      </div>
    </div>

    <b-table
      ref="table"
      :data="table.data"
      :mobile-cards="true"
      :default-sort="[table.sort.field, table.sort.direction]"
      :loading="table.loading"
      backend-sorting
      hoverable
      striped
      class="has-vertical-align-middle"
      @click="openReport"
    >
      <template slot="empty">
        <no-results
          icon="box-open"
          title="No reports"
          message="There are no reports to show."
        />
      </template>

      <template
        slot-scope="props"
        :has-detailed-visible="!$_.isEmpty(props.row.files)"
      >
        <b-table-column label="Name" field="name" class="has-cursor-pointer">
          <router-link :to="`/reports/site/${siteId}/${props.row._id}`">
            <u class="has-text-dark">{{ props.row.name || "-" }}</u>
          </router-link>
        </b-table-column>
      </template>
    </b-table>

    <load-more
      v-if="!table.complete"
      :loading="table.loading"
      @loadMore="getData(true)"
    />
  </div>
</template>

<script>
import { collection } from "@firebase/firestore";
export default {
  name: "SiteReportsTable",
  mixins: [require("@mixins/table-with-filtering-and-sorting").default],
  props: {
    siteId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      pageLoading: true,
      processing: false,
      selectedReportId: null
    };
  },
  computed: {
    site() {
      return this.$store.getters["sites/site"](this.siteId);
    },
    userIsAgent() {
      return this.$store.getters["user/isAgent"]();
    }
  },
  methods: {
    openReport(row) {
      this.$router.push({ path: `/reports/site/${this.siteId}/${row._id}` });
    },
    generateMonthlyReport() {
      this.processing = true;
      this.$store
        .dispatch("sites/generateMonthlyReport", { siteId: this.siteId })
        .then(({ message }) => {
          this.processing = false;
          this.$toast.open({
            message,
            type: "is-success"
          });
          this.getData();
        })
        .catch(error => {
          this.processing = false;
          this.$toast.open({
            message: error,
            type: "is-danger"
          });
        });
    },
    getFirestoreRef() {
      let ref = collection(this.$firestore, `sites/${this.siteId}/reports`);
      return this.buildFirestoreRef(ref);
    },
    afterDataLoad() {
      this.pageLoading = false;
    }
  }
};
</script>
